import React from 'react';
import { Typography } from '@mui/material';

import { Venue } from '@gql/types/graphql';
import { StyledVenueName } from './VenueNameCell.styled';

interface VenueNameProps {
  venue: Pick<Venue, 'name' | 'city' | 'stateCode'>;
  tierName: string;
}

export function VenueNameCell({ venue, tierName }: VenueNameProps) {
  return (
    <StyledVenueName>
      <Typography variant="body2">
        {venue.name}
      </Typography>
      <Typography variant="caption-small" color="text.secondary">
        {venue.city}, {venue.stateCode} | {tierName}
      </Typography>
    </StyledVenueName>
  );
}
