import {
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-pro';

import {
  TotalsCell,
  VenueNameCell,
  ShowsCountCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import {
  formatNumberToCurrency,
  formatTotalsToHumanReadable,
  formatTotalsToCurrencyOrDash,
} from '@utils/numberHelpers';
import { FinancesBoxOfficeEventOffer, FinancesBoxOfficeTier } from '@types';

const leftPinnedColumn = {
  headerName: '',
  flex: 0.2,
  minWidth: 178,
  maxWidth: 248,
};

const showsColumn = {
  field: 'totalShowsCount',
  headerName: 'Shows',
  flex: 0.1,
  minWidth: 82,
};

const capacityColumn = {
  field: 'capacity',
  headerName: 'Capacity',
  flex: 0.11,
  minWidth: 97,
};

const killsColumn = {
  field: 'kills',
  headerName: 'Kills',
  flex: 0.1,
  minWidth: 64,
};

const compsColumn = {
  field: 'comps',
  headerName: 'Comps',
  flex: 0.1,
  minWidth: 82,
};

const sellableCapacityColumn = {
  field: 'sellableCapacity',
  headerName: 'Available Capacity',
  flex: 0.12,
  minWidth: 99,
};

const totalPriceColumn = {
  field: 'totalPrice',
  headerName: 'Price',
  flex: 0.1,
  minWidth: 90,
};

const grossPotentialColumn = {
  field: 'grossPotential',
  headerName: 'Gross Potential',
  flex: 0.14,
  minWidth: 125,
};

const netGrossPotentialColumn = {
  field: 'netGrossPotential',
  headerName: 'Net Gross Receipt',
  flex: 0.14,
  minWidth: 125,
};

type BoxOfficeColumns = GridColDef<FinancesBoxOfficeTier>[];

export const boxOfficeColumns: BoxOfficeColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => !params.row.isTotalsRow && <ExpandButtonCell {...params} />,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    renderCell: ({ row }) => (
      <LeftPinnedCell>
        <TextWrapPrettyCell fontWeight={row.isTotalsRow ? '500' : 'normal'}>{row.name}</TextWrapPrettyCell>
        {!row.isTotalsRow && <ShowsCountCell count={row.eventOffers.length} total={row.localOfferTotal} />}
      </LeftPinnedCell>
    ),
  },
  showsColumn,
  {
    ...capacityColumn,
    valueGetter: ({ row }) => formatTotalsToHumanReadable(row.boxOffice.capacity),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={capacityColumn.headerName} />
    ),
  },
  {
    ...killsColumn,
    valueGetter: ({ row }) => formatTotalsToHumanReadable(row.boxOffice.kills),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={killsColumn.headerName} />
    ),
  },
  {
    ...compsColumn,
    valueGetter: ({ row }) => formatTotalsToHumanReadable(row.boxOffice.comps),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={compsColumn.headerName} />
    ),
  },
  {
    ...sellableCapacityColumn,
    valueGetter: ({ row }) => formatTotalsToHumanReadable(row.boxOffice.sellableCapacity),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={sellableCapacityColumn.headerName} />
    ),
  },
  {
    ...totalPriceColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.boxOffice.price),
  },
  {
    ...grossPotentialColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.boxOffice.grossPotential),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={grossPotentialColumn.headerName} />
    ),
  },
  {
    ...netGrossPotentialColumn,
    valueGetter: ({ row }) => formatTotalsToCurrencyOrDash(row.boxOffice.netGrossPotential),
    renderCell: ({ formattedValue, row }) => (
      <TotalsCell {...formattedValue} bold={row.isTotalsRow} name={netGrossPotentialColumn.headerName} />
    ),
  },
];

type BoxOfficeEventOfferColumns = GridColDef<FinancesBoxOfficeEventOffer>[];

export const boxOfficeEventOfferColumns: BoxOfficeEventOfferColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: ({ row: eventOffer }) => (
      <LeftPinnedCell>
        <VenueNameCell venue={eventOffer.venue} tierName={eventOffer.boxOffice.name} />
        <EventOfferActionsCell
          updatedDate={eventOffer.updatedDate}
          offerId={eventOffer.id}
        />
      </LeftPinnedCell>
    ),
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  {
    ...showsColumn,
    valueGetter: ({ row }) => row.showsCount?.toLocaleString(),
  },
  {
    ...capacityColumn,
    valueGetter: ({ row }) => row.boxOffice.capacity?.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  },
  {
    ...killsColumn,
    valueGetter: ({ row }) => row.boxOffice.kills?.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  },
  {
    ...compsColumn,
    valueGetter: ({ row }) => row.boxOffice.comps?.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  },
  {
    ...sellableCapacityColumn,
    valueGetter: ({ row }) => row.boxOffice.sellableCapacity?.toLocaleString('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
  },
  {
    ...totalPriceColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.boxOffice.price),
  },
  {
    ...grossPotentialColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.boxOffice.grossPotential),
  },
  {
    ...netGrossPotentialColumn,
    valueGetter: ({ row }) => formatNumberToCurrency(row.boxOffice.netGrossPotential),
  },
];
